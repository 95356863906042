import React, { useEffect } from 'react';
import AOS from 'aos';

// Override default AOS styles to prevent conflicts
// After update AOS to new version need patch styles
import './styles/sass/aos.scss';
import './styles/polaris.css';
import 'react-phone-number-input/style.css';
import 'swiper/swiper.min.css';

import { AppState } from './context/AppState';
import { Content } from './components/content/Content';

// eslint-disable-next-line
export const App: React.FC<{ index: number; element: any }> = ({
  index,
  element,
}) => {
  useEffect(() => {
    AOS.init({
      once: true,
      duration: 300,
    });
  }, []);

  return (
    <AppState index={index} element={element}>
      <Content />
    </AppState>
  );
};
