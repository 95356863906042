export function getCustomerCompletion(key: string) {
  const quizKitCustomerCompletions = JSON.parse(
    localStorage.getItem('quizKitCustomerCompletions') || 'null',
  );
  let data;

  if (quizKitCustomerCompletions) {
    Object.keys(quizKitCustomerCompletions).forEach(id => {
      if (id === key && !quizKitCustomerCompletions[id].redirectUrl)
        data = quizKitCustomerCompletions[id];
    });
  }

  return data;
}
