export function getFocusableElements(
  container: HTMLElement,
): HTMLElement[] | [] {
  return Array.from(
    container.querySelectorAll(
      "summary, a[href], button:not(.disabled), button:not([tabindex='-1']) button:enabled, [tabindex]:not([tabindex^='-']), [draggable], area, input:not([type=hidden]):enabled, select:enabled, textarea:enabled, object, iframe",
    ),
  );
}

type TrapFocusHandlers = {
  focusin?: (event: Event) => void;
  focusout?: (event: Event) => void;
  keydown?: (event: KeyboardEvent) => void;
};

const trapFocusHandlers: TrapFocusHandlers = {};

export function removeTrapFocus(elementToFocus?: HTMLElement) {
  if (trapFocusHandlers.focusin)
    document.removeEventListener('focusin', trapFocusHandlers.focusin);
  if (trapFocusHandlers.focusout)
    document.removeEventListener('focusout', trapFocusHandlers.focusout);
  if (trapFocusHandlers.keydown)
    document.removeEventListener('keydown', trapFocusHandlers.keydown);

  if (elementToFocus) elementToFocus.focus();
}

export function trapFocus(container: HTMLElement, elementToFocus = container) {
  let elements = getFocusableElements(container);
  let first = elements[0];
  let last = elements[elements.length - 1];

  removeTrapFocus();

  trapFocusHandlers.keydown = function (event: KeyboardEvent) {
    if (event.code.toUpperCase() !== 'TAB') return; // If not TAB key

    elements = getFocusableElements(container);
    const [firstElement] = elements;
    first = firstElement;
    last = elements[elements.length - 1];

    // On the last focusable element and tab forward, focus the first element.
    if (event.target === last && !event.shiftKey) {
      event.preventDefault();
      first.focus();
    }

    //  On the first focusable element and tab backward, focus the last element.
    if (
      (event.target === container || event.target === first) &&
      event.shiftKey
    ) {
      event.preventDefault();
      last.focus();
    }
  };

  trapFocusHandlers.focusin = (event: Event) => {
    if (
      event.target !== container &&
      event.target !== last &&
      event.target !== first
    )
      return;

    if (trapFocusHandlers.keydown)
      document.addEventListener('keydown', trapFocusHandlers.keydown);
  };

  trapFocusHandlers.focusout = function () {
    if (trapFocusHandlers.keydown)
      document.removeEventListener('keydown', trapFocusHandlers.keydown);
  };

  document.addEventListener('focusout', trapFocusHandlers.focusout);
  document.addEventListener('focusin', trapFocusHandlers.focusin);

  elementToFocus.focus();
}
