import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './App';
import * as serviceWorker from './serviceWorker';
import {
  checkQuizURL,
  transformQuizLinksToPopups,
} from './helpers/transformQuizLinksToPopups';
import { checkCustomersCompletions } from './helpers/checkCustomersCompletions';

declare let __webpack_public_path__: string;
// eslint-disable-next-line prefer-const
__webpack_public_path__ = `${process.env.PUBLIC_URL}/`;

checkCustomersCompletions();
transformQuizLinksToPopups();
checkQuizURL();

const quizzes = document.querySelectorAll('[data-quiz-kit-key]');

quizzes.forEach((quiz, index) => {
  ReactDOM.render(<App index={index} element={quiz} />, quiz);
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
