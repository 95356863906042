import ReactDOM from 'react-dom';
import { App } from '../App';
import React from 'react';
import { removeTrapFocus, trapFocus } from './trapFocus';

function getQuizPopup(
  id: string,
  index: number,
  personalizationKey: string | null,
  elementToFocus?: HTMLElement,
) {
  const closeIcon = document.createElement('button');
  const shortcode = document.createElement('div');
  let popup: HTMLElement = document.createElement('div');

  if (personalizationKey)
    shortcode.setAttribute('data-quiz-kit-personalization', id);
  shortcode.classList.add('quizkit-popup__content');
  shortcode.setAttribute('data-quiz-kit-key', id);
  closeIcon.classList.add('quizkit-popup__close');
  popup.classList.add('quizkit-popup');
  popup.setAttribute('id', `a${id}`);
  popup.append(closeIcon, shortcode);

  const popupOpen = () => {
    const popupAppended = document.getElementById(`a${id}`);

    if (popupAppended) {
      popup = popupAppended;
    } else {
      document.body.appendChild(popup);
      ReactDOM.render(
        <App index={index + 100} element={shortcode} />,
        shortcode,
      );
    }

    popup.style.display = 'block';
    setTimeout(() => {
      popup.style.opacity = '1';
      trapFocus(popup, closeIcon);
    }, 10);
  };

  closeIcon.addEventListener('click', () => {
    const popupAppended = document.getElementById(`a${id}`);
    if (popupAppended) {
      popup = popupAppended;
    }
    popup.style.opacity = '0';
    setTimeout(() => {
      popup.style.display = 'none';
      removeTrapFocus(elementToFocus);
    }, 300);
  });

  return { popupOpen };
}

export function transformQuizLinksToPopups() {
  const $quizLinks = document.querySelectorAll('a[href^="#quiz-kit-key-"]');

  if ($quizLinks.length) {
    $quizLinks.forEach(($el, index) => {
      const id = $el.getAttribute('href')?.replace('#quiz-kit-key-', '') || '';
      const personalizationKey = $el.getAttribute(
        'data-quiz-kit-personalization',
      );

      const { popupOpen } = getQuizPopup(
        id,
        index,
        personalizationKey,
        $el as HTMLElement,
      );

      $el.addEventListener('click', e => {
        e.preventDefault();
        popupOpen();
      });
    });
  }
}

export function checkQuizURL() {
  const HASH_NAME = '#quiz-kit-key-';
  const { hash } = window.location;
  if (!hash.includes(HASH_NAME)) return;
  const quizId = hash.replace(HASH_NAME, '');

  const { popupOpen } = getQuizPopup(quizId, 0, null);

  popupOpen();
}
