import React from 'react';
import {
  SkeletonWrapper,
  SkeletonBoxes,
  SkeletonBox,
  SkeletonLine,
  SkeletonLineAnimation,
} from '../../styles/styles';

export const SkeletonLoader = () => {
  return (
    <SkeletonWrapper id="quiz-kit-skeleton-wrapper">
      <SkeletonLine width={400} color="#d9d9d9">
        <SkeletonLineAnimation
          color="#d9d9d9"
          image="linear-gradient(90deg, #d9d9d9, #e8e8e8, #d9d9d9)"
        />
      </SkeletonLine>
      <SkeletonLine>
        <SkeletonLineAnimation />
      </SkeletonLine>

      <SkeletonBoxes>
        <SkeletonBox>
          <SkeletonLine width={256} height={256} marginBottom={20}>
            <SkeletonLineAnimation />
          </SkeletonLine>
          <SkeletonLine color="#d9d9d9">
            <SkeletonLineAnimation
              color="#d9d9d9"
              image="linear-gradient(90deg, #d9d9d9, #e8e8e8, #d9d9d9)"
            />
          </SkeletonLine>
          <SkeletonLine width={100}>
            <SkeletonLineAnimation />
          </SkeletonLine>
        </SkeletonBox>
        <SkeletonBox>
          <SkeletonLine width={256} height={256} marginBottom={20}>
            <SkeletonLineAnimation />
          </SkeletonLine>
          <SkeletonLine color="#d9d9d9">
            <SkeletonLineAnimation
              color="#d9d9d9"
              image="linear-gradient(90deg, #d9d9d9, #e8e8e8, #d9d9d9)"
            />
          </SkeletonLine>
          <SkeletonLine width={100}>
            <SkeletonLineAnimation />
          </SkeletonLine>
        </SkeletonBox>
        <SkeletonBox>
          <SkeletonLine width={256} height={256} marginBottom={20}>
            <SkeletonLineAnimation />
          </SkeletonLine>
          <SkeletonLine color="#d9d9d9">
            <SkeletonLineAnimation
              color="#d9d9d9"
              image="linear-gradient(90deg, #d9d9d9, #e8e8e8, #d9d9d9)"
            />
          </SkeletonLine>
          <SkeletonLine width={100}>
            <SkeletonLineAnimation />
          </SkeletonLine>
        </SkeletonBox>
        <SkeletonBox>
          <SkeletonLine width={256} height={256} marginBottom={20}>
            <SkeletonLineAnimation />
          </SkeletonLine>
          <SkeletonLine color="#d9d9d9">
            <SkeletonLineAnimation
              color="#d9d9d9"
              image="linear-gradient(90deg, #d9d9d9, #e8e8e8, #d9d9d9)"
            />
          </SkeletonLine>
          <SkeletonLine width={100}>
            <SkeletonLineAnimation />
          </SkeletonLine>
        </SkeletonBox>
      </SkeletonBoxes>
    </SkeletonWrapper>
  );
};
