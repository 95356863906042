const userDataObject: { [key: string]: string } = {
  Phone: 'phone',
  Birthday: 'dateOfBirth',
  Email: 'email',
  Name: 'firstName',
};
export const getResultUserData = (
  data: { [key: string]: string | null },
  fields: string[],
): { [key: string]: string } => {
  return fields.reduce((acc, field) => {
    const keyInData = userDataObject[field];
    const value = data[keyInData];
    if (value !== null) {
      acc[field] = value;
    }
    return acc;
  }, {} as { [key: string]: string });
};
