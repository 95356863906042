export const CHANGE_STEP = 'CHANGE_STEP';
export const FETCH_DATA = 'FETCH_DATA';
export const CHANGE_QUESTION = 'CHANGE_QUESTION';
export const CHOOSE_ANSWER = 'CHOOSE_ANSWER';
export const DELETE_ANSWER = 'DELETE_ANSWER';
export const RETAKE_QUIZ = 'RETAKE_QUIZ';
export const SET_QUIZ_KEY = 'SET_QUIZ_KEY';
export const SET_INPUT_VALUE = 'SET_INPUT_VALUE';
export const DELETE_INPUT_VALUE = 'DELETE_INPUT_VALUE';
export const START_QUIZ = 'START_QUIZ';
export const SET_ANSWER_DATA = 'SET_ANSWER_DATA';
export const SET_GDPR = 'SET_GDPR';
export const CLEAN_INPUT_VALUE = 'CLEAN_INPUT_VALUE';
export const CLEAN_INPUT_ERROR = 'CLEAN_INPUT_ERROR';
export const SET_INPUT_ERRORS = 'SET_INPUT_ERRORS';
