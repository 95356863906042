import { useCallback, useState } from 'react';
import { fontsLoader, prepareFonts } from '../helpers/fonts-loader';

export function useFontsLoader() {
  const [status, setStatus] = useState('idle');

  return useCallback(
    async (initFonts: (string | undefined)[]) => {
      if (['pending', 'success'].includes(status)) return;

      const fonts = prepareFonts(initFonts);

      if (['error', 'idle'].includes(status) && fonts.length) {
        setStatus('pending');
        await fontsLoader(fonts, {
          onError: () => setStatus('error'),
          onSuccess: () => setStatus('success'),
        });
      }
    },
    [status],
  );
}
