import * as types from './appTypes';
import { Action, State } from '../types/types';

export const appReducer = (state: State, action: Action) => {
  if (action.type === types.START_QUIZ) {
    return { ...state, quizStarted: action.payload };
  }
  if (action.type === types.CHANGE_STEP) {
    return { ...state, step: action.payload };
  }
  if (action.type === types.FETCH_DATA) {
    return {
      ...state,
      ...action.payload,
    };
  }
  if (action.type === types.CHANGE_QUESTION) {
    return {
      ...state,
      questionNumber: action.payload.next,
      questionsPath: action.payload.prev
        ? [...state.questionsPath, action.payload.prev]
        : [...state.questionsPath].slice(0, state.questionsPath.length - 1),
    };
  }
  if (action.type === types.SET_INPUT_VALUE) {
    const inputValues = { ...state.inputValues };
    const { id, value } = action.payload;
    inputValues[id] = value;

    return { ...state, inputValues };
  }
  if (action.type === types.SET_INPUT_ERRORS) {
    const inputErrors = { ...state.inputErrors };
    const { id, value } = action.payload;
    inputErrors[id] = value;

    return { ...state, inputErrors };
  }
  if (action.type === types.CLEAN_INPUT_ERROR) {
    const inputErrors = { ...state.inputErrors };
    delete inputErrors[action.payload];

    return { ...state, inputErrors };
  }
  if (action.type === types.CLEAN_INPUT_VALUE) {
    const inputValues = { ...state.inputValues };
    delete inputValues[action.payload];

    return { ...state, inputValues };
  }
  if (action.type === types.DELETE_INPUT_VALUE) {
    const questionId = action.payload;
    const inputValues = { ...state.inputValues };
    delete inputValues[questionId];

    return { ...state, inputValues };
  }
  if (action.type === types.CHOOSE_ANSWER) {
    const { questionId, answersArray } = action.payload;
    const { chosenAnswers } = state;
    // @ts-ignore
    chosenAnswers[questionId] = answersArray;

    return {
      ...state,
      chosenAnswers,
    };
  }
  if (action.type === types.DELETE_ANSWER) {
    const questionId = action.payload;
    const chosenAnswers = { ...state.chosenAnswers };
    delete chosenAnswers[questionId];

    return {
      ...state,
      chosenAnswers,
    };
  }
  if (action.type === types.RETAKE_QUIZ) {
    return {
      ...state,
      questionNumber: 0,
      step: 'question',
      chosenAnswers: {},
      inputValues: {},
      questionsPath: [],
      retakeQuiz: true,
      predefinedValues: {
        ...state.predefinedValues,
        deliveryFrequency: '',
      },
    };
  }
  if (action.type === types.SET_QUIZ_KEY) {
    return { ...state, quizKey: action.payload };
  }
  if (action.type === types.SET_ANSWER_DATA) {
    return {
      ...state,
      predefinedQuantities: {
        ...state.predefinedQuantities,
        ...action.payload.predefinedQuantities,
      },
      predefinedValues: {
        ...state.predefinedValues,
        ...action.payload.predefinedValues,
      },
      redirectUrl: action.payload.redirectUrl || state.redirectUrl,
    };
  }
  if (action.type === types.SET_GDPR) {
    return { ...state, gdprChecked: action.payload };
  }
  return state;
};
