import React from 'react';
import { SpinnerWrapper } from '../../styles/styles';

export const Spinner = ({ backgroundColor }: { backgroundColor?: string }) => {
  return (
    <SpinnerWrapper
      id="quiz-kit-spinner"
      backgroundColor={backgroundColor || 'inherit'}
    >
      <svg className="spinner" viewBox="0 0 50 50">
        <circle
          className="path"
          cx="25"
          cy="25"
          r="20"
          fill="none"
          strokeWidth="3"
          stroke="currentColor"
        />
      </svg>
    </SpinnerWrapper>
  );
};
