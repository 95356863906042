import { State } from '../../types/types';

export const getContentStyles = (state: State, layoutId: string) => {
  const { step, quiz } = state;
  const { styles, collectEmail, background } = quiz;
  const minHeight = styles?.general?.minHeight;
  const layoutBackground = layoutId
    ? styles?.resultsLayouts?.layouts?.[layoutId as string]?.styles
        ?.backgroundColor
    : '';
  const resultsBackground =
    layoutBackground || styles?.results?.backgroundColor;
  const resultsPageBackground = collectEmail
    ? styles?.subscribe?.backgroundColor || resultsBackground
    : resultsBackground;
  let backgroundColor = 'inherit';

  if (step === 'introPage') {
    backgroundColor = background?.backgroundColor;
  } else if (step === 'question') {
    backgroundColor = styles?.questions?.backgroundColor;
  } else if (step === 'resultPage' || step === 'resultPagePredefined') {
    backgroundColor = resultsPageBackground;
  }

  return {
    minHeight,
    backgroundColor,
    hideWatermarkLegacy: styles?.general?.hideWatermark,
  };
};
