import axios from 'axios';

export const fetchDataWithResultId = (
  key: string,
  resultPageId: string,
): Promise<any> => {
  return axios
    .get(
      `${
        process.env.REACT_APP_API_URL
      }/api/v1/quizzes/${key}/resultPages/${Number(resultPageId)}`,
    )
    .then(response => {
      return response.data;
    })
    .catch(() => {
      console.log('Something went wrong');
    });
};
