import { SingleProductType } from '../types/types';

function getURLPath(url: string) {
  try {
    return new URL(url).pathname;
  } catch (err) {
    return url;
  }
}

export function checkCustomersCompletions() {
  const quizKitCustomerCompletions = JSON.parse(
    localStorage.getItem('quizKitCustomerCompletions') || 'null',
  );

  if (quizKitCustomerCompletions) {
    Object.keys(quizKitCustomerCompletions).forEach(key => {
      const completion = quizKitCustomerCompletions[key];
      const {
        url,
        label,
        accountSection,
        cartSection,
        hpSection,
        pdpSection,
        plpSection,
        footerId,
        updateTheLabel,
        openInAPopup,
        personalizationVersion,
      } = completion?.personalization;
      const products: SingleProductType[] =
        completion?.recommendedProducts || [];
      const links = document.querySelectorAll(`a[href$="${getURLPath(url)}"]`);
      const { pathname } = window.location;
      const personalizationSection = document.getElementById(
        'quiz-kit-personalization-section',
      );
      const updateLabel =
        personalizationVersion || updateTheLabel
          ? updateTheLabel === 'enabled'
          : true;
      const createAPopup =
        personalizationVersion || openInAPopup
          ? openInAPopup === 'enabled'
          : true;

      links.forEach($el => {
        if (
          updateLabel &&
          !$el.innerHTML.includes('<img') &&
          !$el.innerHTML.includes('<div') &&
          $el.innerHTML !== '' &&
          $el.getAttribute('data-menu-item') !== 'diagnostic' &&
          $el.getAttribute('class') !== 'promobanner__text'
        )
          $el.innerHTML = label || 'My matches';

        if (createAPopup) {
          $el.setAttribute('data-quiz-kit-personalization', key);
          $el.setAttribute('href', `#quiz-kit-key-${key}`);
        }
      });

      const isSectionEnabled = (section: string) => section === 'enabled';
      const isCurrentPage = (page: string) => {
        if (page === '/') return pathname === '/';
        return pathname.includes(page);
      };
      const footer = document.getElementById(
        footerId || 'shopify-section-footer',
      );
      const isResultProductType =
        completion?.ruleset?.ruleset?.type !== 'text' &&
        completion?.ruleset?.ruleset?.type !== 'redirect';

      if (isCurrentPage('/products') && products.length) {
        const handle = pathname.substring(pathname.lastIndexOf('/') + 1);
        const badgeElement = document.getElementById(
          'quiz-kit-personalization-match',
        );

        if (products.some(product => product.handle === handle) && badgeElement)
          badgeElement.style.display = 'block';
      }

      if (
        personalizationSection &&
        personalizationSection.getAttribute('data-quiz-kit-personalization') ===
          completion?.quizKey &&
        isResultProductType
      ) {
        personalizationSection.setAttribute(
          'data-quiz-kit-key',
          completion?.quizKey,
        );
      } else if (
        ((isSectionEnabled(hpSection) && isCurrentPage('/')) ||
          (isSectionEnabled(pdpSection) && isCurrentPage('/products')) ||
          (isSectionEnabled(plpSection) && isCurrentPage('/collections')) ||
          (isSectionEnabled(cartSection) && isCurrentPage('/cart')) ||
          (isSectionEnabled(accountSection) && isCurrentPage('/account'))) &&
        footer &&
        isResultProductType
      ) {
        const shortcode = document.createElement('div');

        shortcode.setAttribute(
          'data-quiz-kit-personalization',
          completion?.quizKey,
        );
        shortcode.setAttribute(
          'data-quiz-kit-personalization-section',
          completion?.quizKey,
        );
        shortcode.setAttribute('data-quiz-kit-key', completion?.quizKey);
        footer.parentNode?.insertBefore(shortcode, footer);
      }
    });
  }
}
