import React, { useEffect, useReducer } from 'react';
import { AppContext } from './AppContext';
import { fetchData } from '../helpers/fetchData';
import { appReducer } from './appReducer';
import * as types from './appTypes';
import { AppType, initialState, Data } from '../types/types';
import { useFontsLoader } from '../hooks/useFontsLoader';
import { fetchDataWithResultId } from '../helpers/fetchDataWithResultId';
import { getChosenAnswersWithInputValue } from '../helpers/getChosenAnswersWithInputValue';
import { getResultUserData } from '../helpers/getResultUserData';

export const AppState: React.FC<AppType> = props => {
  const [state, dispatch] = useReducer(appReducer, initialState);
  const { index, children, element } = props;
  const fontsLoader = useFontsLoader();

  const { hash } = window.location;
  const searchParams = new URLSearchParams(
    hash ? hash.split('?')[1] : window.location.search,
  );

  const paramsResultId = searchParams.get('quizKitResultId');

  async function getQuizData(quizKey: string) {
    dispatch({
      type: types.CHANGE_STEP,
      payload: 'loading',
    });

    const personalizationKey = element.getAttribute(
      'data-quiz-kit-personalization',
    );
    const personalizationSection = element.getAttribute(
      'data-quiz-kit-personalization-section',
    );
    const quizKitCustomerCompletions =
      JSON.parse(
        localStorage.getItem('quizKitCustomerCompletions') || 'null',
      ) || {};
    const quizCompleted =
      quizKitCustomerCompletions && quizKey in quizKitCustomerCompletions;
    const isPopup = element.classList.contains('quizkit-popup__content');
    element.removeAttribute('id');

    const data: Data | null = await fetchData(quizKey);
    const quiz = data?.quiz;
    const personalization = quiz?.personalization;
    const featuresList = data?.featuresList || [];
    const hideWatermark = data?.hideWatermark;
    const personalizationAvailable = featuresList.includes('personalization');
    const skipIntro =
      (personalizationKey ||
        (quizCompleted && personalization?.automation === 'enabled')) &&
      !quizKitCustomerCompletions[quizKey]?.redirectUrl;
    const chosenAnswers =
      quizCompleted && skipIntro
        ? quizKitCustomerCompletions[quizKey]?.answers || {}
        : {};
    const inputValues =
      quizCompleted && skipIntro
        ? quizKitCustomerCompletions[quizKey]?.inputValues || {}
        : {};

    const questions = data?.quiz.questions;
    const questionsTypeContact = questions?.find(
      item => item.type === 'contact',
    );
    const questionsTypeContactId =
      questionsTypeContact && questionsTypeContact.id;

    const questionsTypeContactFields =
      questionsTypeContact &&
      questionsTypeContact.meta.fields.map(
        (item: { name: string }) => item.name,
      );

    const dataWithURL =
      paramsResultId && (await fetchDataWithResultId(quizKey, paramsResultId));

    let chosenAnswersWithURL;
    let inputValuesWithURL;

    if (dataWithURL) {
      const {
        chosenAnswersWithURL: chosenAnswersResultPage,
        inputValuesWithURL: inputValuesResultPage,
      } = getChosenAnswersWithInputValue(dataWithURL);

      chosenAnswersWithURL = chosenAnswersResultPage;
      inputValuesWithURL = inputValuesResultPage;

      if (questionsTypeContactId) {
        chosenAnswersWithURL[questionsTypeContactId] = [];
      }

      if (questionsTypeContactId)
        inputValuesWithURL[questionsTypeContactId] = getResultUserData(
          dataWithURL.userData,
          questionsTypeContactFields,
        );
    }

    if (
      !data ||
      (personalizationSection &&
        quizKitCustomerCompletions[quizKey]?.redirectUrl) ||
      (personalizationSection && !quizKitCustomerCompletions[quizKey])
    ) {
      if (isPopup) {
        element.parentNode.remove();
      } else {
        element.remove();
      }

      dispatch({
        type: types.CHANGE_STEP,
        payload: 'initialState',
      });
    } else if (data) {
      dispatch({
        type: types.SET_QUIZ_KEY,
        payload: quiz?.quizKey,
      });

      if (isPopup && !featuresList.includes('advanced_publish_options')) {
        element.parentNode.remove();
      } else if (personalizationSection && !personalizationAvailable) {
        element.remove();
      } else {
        const resultWithUrl = paramsResultId && dataWithURL;
        let step;

        if (resultWithUrl) {
          step = 'resultPage';
        } else if (skipIntro) {
          step = 'resultPagePredefined';
        } else {
          step = 'introPage';
        }

        const payload = {
          ...data,
          step,
          personalizationSection: !!personalizationSection,
          chosenAnswers: resultWithUrl ? chosenAnswersWithURL : chosenAnswers,
          inputValues: resultWithUrl ? inputValuesWithURL : inputValues,
          isPopup,
          hideWatermark,
        };

        dispatch({ type: types.FETCH_DATA, payload });

        const initialFonts = [
          quiz?.title?.fontFamily,
          quiz?.description?.fontFamily,
          quiz?.button?.fontFamily,
        ];

        fontsLoader(initialFonts).catch();
      }
    }
  }

  useEffect(() => {
    const quizKeyStr = element.getAttribute('data-quiz-kit-key');
    const quizKey = quizKeyStr.split('?')[0];

    if (quizKey) {
      getQuizData(quizKey);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index]);

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  );
};
