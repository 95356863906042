import { Answer, ChosenAnswersURL, InputValuesWithURL } from '../types/types';

export const getChosenAnswersWithInputValue = (dataWithURL: {
  answers: Answer[];
}) => {
  const inputValuesWithURL: InputValuesWithURL = {};
  const { answers }: { answers: Answer[] } = dataWithURL;

  const chosenAnswersWithURL = answers.reduce<ChosenAnswersURL>(
    (acc, { questionId, selectedAnswers }) => {
      const ids = selectedAnswers
        .filter(({ id }) => id !== undefined)
        .map(({ id }) => id!);

      if (ids.length > 0) {
        if (!acc[questionId]) {
          acc[questionId] = [];
        }
        acc[questionId].push(...ids);
      }

      if (!acc[questionId]) {
        acc[questionId] = [];
      }

      return acc;
    },
    {},
  );

  answers
    .filter(({ selectedAnswers }) => !selectedAnswers[0].id)
    .forEach(({ questionId, questionTitle, selectedAnswers }) => {
      if (!inputValuesWithURL[questionId]) {
        inputValuesWithURL[questionId] = {};
      }
      inputValuesWithURL[questionId][questionTitle] = selectedAnswers[0].value;
    });

  return { chosenAnswersWithURL, inputValuesWithURL };
};
