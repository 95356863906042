import axios from 'axios';
import { Data } from '../types/types';

export const fetchData = (key: string): Promise<Data> => {
  axios.defaults.headers.common['ngrok-skip-browser-warning'] = 'true';

  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/api/v1/quizzes/${key}?url=${
        window.location.href.split('?')[0]
      }`,
    )
    .then(res => {
      return res.data;
    })
    .catch(() => {
      return null;
    });
};
