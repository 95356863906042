type FontsLoaderOptions = {
  onError(): void;
  onSuccess(): void;
};

export async function fontsLoader(
  fonts: string[],
  options: FontsLoaderOptions,
) {
  if (!fonts || !fonts.length) return;

  try {
    const WebFont = await import('webfontloader');
    WebFont.load({
      active: options.onSuccess,
      inactive: options.onError,
      google: { families: fonts },
    });
  } catch (e) {
    console.error(e);
  }
}

export function prepareFonts(fonts: (string | undefined)[]) {
  const filteredFonts = fonts.filter((element, index) => {
    return (
      element &&
      !['Default', 'inherit', 'undefined'].includes(element) &&
      fonts.indexOf(element) === index
    );
  });

  if (filteredFonts.length) {
    return [
      `${filteredFonts.join(
        '|',
      )}:100,200,300,400,500,600,700,800,900&display=swap`,
    ];
  }

  return [];
}
